<template>
  <section class="hero hero-bg-contact">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6">
            <div class="box">
              <h1 class="title has-text-danger">
                Désolé service indisponible
              </h1>
              <p class="has-text-danger">
                Nous rencontrons quelques difficultés, merci de réessayer plus tard.
              </p>
              <div class="column">
                <a href="https://web.facebook.com/anveliclinic/">
                  <img
                    width="50"
                    :src="require('../assets/img/home/logo-fb.png')"
                    alt="Notre FaceBook"
                  >
                </a>
                <a href="https://www.youtube.com/channel/UC24h84rPZBeQrMEfPuRXrmg?sub_confirmation=1">
                  <img
                    width="50"
                    :src="require('../assets/img/home/logo-youtube.png')"
                    alt="Notre chaine YouTube"
                  >
                </a>
                <a href="https://www.instagram.com/accounts/login/?next=%2Fanveli_dental%2F&source=follow">
                  <img
                    width="50"
                    :src="require('../assets/img/home/logo-insta.png')"
                    alt="Notre Instargram"
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'ErrorContent'
  }
</script>

<style scoped>
  .hero-bg-contact {
    background-size: cover;
    background: url("../assets/img/dental-care/h1-soins-dentaires-d.jpg") no-repeat center;
    padding: 15rem 0;
  }
  .box {
    background-color: rgba(10, 10, 10, 0.03);
  }
</style>
